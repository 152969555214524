import React from "react"
import PageRoot from "../components/PageRoot"
import NoDataScreen from "../components/NoDataScreen"
import { useTranslation } from "react-i18next"

const NotFoundPage = () => {
  const { t } = useTranslation()
  return (
    <PageRoot title={t("label:pageNotFound")} showTitle>
      <h1>404: Not Found</h1>
      <NoDataScreen />
    </PageRoot>
  )
}

export default NotFoundPage
